<template>
	<div class="main flex" >
		<div class="tagBox" v-if="tag||classify">
			{{tag||classify}} 标签
		</div>

		
		<div class="box">
			<TextLoading v-if="loading"></TextLoading>
			<div class="article slide"  v-else >
				<router-link :to="{path:`/blog/${item.id}`}" class="list animate03" v-for="(item, index) in articles":key="index">
					<div class="classifybox">
						<div class="classify">{{item.class_name}}</div>
					</div>
				
					<div class="list-img">
						<img v-imgUrl="item.image" class="footer-bg animate03">
					</div>
				
					<div class="list-main">
						<div class="creattime">
							<i class="iconfont lv-icon-kalendar"></i>
							发布于 {{item.publish_time}}
						</div>
						<h3>{{item.title}}</h3>
						<!-- 有标签才显示 -->
						<div class="tag-box" v-if="item.tags && item.tags.length">
							<i class="iconfont lv-icon-biaoqian6"></i>
							<span v-for="(tagli, index) in item.tags" :key="index" :class="{active: tag==tagli}">
								{{tagli}}
							</span>
						</div>
				
						<div class="comment">
							<span><i class="iconfont lv-icon-huore"></i>{{item.like}}热度</span>
							<span><i class="iconfont lv-icon-xiaoxi3"></i>{{item.click}}条评论</span>
						</div>
					</div>
				</router-link>
				<NewPage :pageModel="pageModel" @selectList="selectRoleList" v-if="pageModel.total>pageModel.list_rows"></NewPage>
			</div>
		
			
			<Skills :page="pageModel" @getArticles="ArticlesOrderByTag" @ArticlesOrderByClassify="ArticlesOrderByTag"
				@ArticlesOrderByTag="ArticlesOrderByTag" >
			</Skills>
			
		</div>
		
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions
	} from "vuex"
	import Skills from '../components/skills'

	export default {
		components: {
			Skills
		},
		data() {
			return {
				loading: false,
				articles: [],
				pageModel:{
					page:1,
				    list_rows:6,
				    total:0,
				},
				last_page:0,//最后一页
				
			}
		},
		computed: {
			...mapGetters([
				'classify', 'tag','page','bannear'
			])
		},
		watch: {
			$router() {}
		},
		created() {
			
			this.getArticleList()
	
		},
		methods: {
			...mapActions([
				'Tag', 'Classify','Page'
			]),
			//获取文章列表
			getArticleList() {
				
				this.pageModel.page = this.$route.query.page?this.$route.query.page*1:1;
				this.Page(this.$route.query.page);
				this.Tag(this.$route.query.tag);
				this.Classify(this.$route.query.classify);
				this.loading = true
				let data ={
					page:this.pageModel.page,
					list_rows:this.pageModel.list_rows,
					tag:this.tag,
					class:this.classify
				};
				this.$Apis.getArticleList(data).then(res => {
					this.pageModel.total = res.data.total
					this.articles = res.data.data
					if(res.data.last_page <this.pageModel.page){
						this.$router.replace('/error')
					}
					
					this.loading = false
					window.scrollTo(0, 0);
				})
			},
			
			
			
			//获取
			selectRoleList(val) {
				
				console.log(888,val);
				this.pageModel.page = val
				let  data = this.$route.query;
				let query = {
					...data,
					page : val,
				}
				this.$router.push({path:'/blog', query:query})
				this.Page(val);
				this.getArticleList();
				
			},
			
			
			// 按标签获取
			ArticlesOrderByTag() {
			
				this.pageModel.page =1
				this.getArticleList();
			},
			
		}
	}
</script>


<style scoped lang="scss">
	.main {
		flex-direction: column;
		max-width: 80%;
	}
	.box{
		display: flex;
		justify-content: space-between;
	}

	.article,
	a {
		font-family: 'Source Han Serif SC', 'Source Han Serif', 'source-han-serif-sc', 'PT Serif', 'SongTi SC', 'MicroSoft Yahei', Georgia, serif;
		color: #34495e;
	}

	.topTab {
		font-size: 16px;
		margin-bottom: 20px;
		padding: 12px 20px;
		box-shadow: 2px 2px 14px #c0dbe6;
	}

	.typeTitle {
		padding: 5px 15px 5px;
		margin-bottom: 17px;
		background: #ecf0f1;
		font-size: 16px;
	}

	.tagBox {
		font-size: 3rem;
		line-height: 50px;
		color: #2d3748;
		font-family: monospace;
		font-weight: bold;
		margin-bottom: 50px;
	}

	.article {
		width: 60%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		
		
	}

	.created {
		color: #888;
		font-size: 12px;

		i {
			color: #959595;
			margin-right: 4px;
			font-size: 14px;
		}
	}

	.title {
		color: #34495e;
		line-height: 30px;
		font-size: 18px;
	}

	.comment {
		line-height: 30px;

		span {
			margin-right: 20px;
			font-size: 12px;
		}

		i {
			margin-right: 4px;
			font-size: 14px;
		}
	}

	.desc {
		margin: 5px 0 10px;
	}

	.tag-box {
		line-height: 24px;
		font-size: 14px;

		span {
			padding: 0 4px;
		}

		i {
			font-size: 12px;
		}

		.tag {
			display: inline-block;
			padding: 2rem 8px;
			border-radius: 3px;
			background: #e8e8e8;
			margin: 5px 5px;
		}

		span.active {
			color: #ea546e;
		}
	}

	.list {
		position: relative;
		display: flex;
		border-radius: 10px;
		margin-bottom: 40px;
		height: 280px;
		font-size: 14px;
		box-shadow: 8px 14px 38px #e7eeef;
		width: 100%;

		.list-main {
			flex: 0 1 358px;
			padding: 25px 40px;

			.creattime {
				text-align: right;
				margin-bottom: 5px;
			}

			h3 {
				font-size: 24px;
				font-weight: 400;
				margin-bottom: 25px;
				 display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
		}

		.list-img {
			flex: 1;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				mix-blend-mode: multiply;
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}
		}

		.classifybox {
			position: absolute;
			left: -8px;
			top: -8px;
			width: 100px;
			height: 100px;
			overflow: hidden;
			z-index: 30;
		}

		.classify {
			width: 220px;
			left: -70px;
			top: 25px;
			text-align: center;
			padding: 4px 15px;
			position: relative;
			color: #fff;
			font-weight: 600;
			background-image: linear-gradient(90deg, #23a6d5, #23d5ab);
			text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
			box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
			transform: rotate(-45deg);
		}

		.classifybox:after,
		.classifybox:before {
			position: absolute;
			z-index: -1;
			content: "";
			display: block;
			border: 4px solid #3d4852;
			border-top-color: transparent;
			border-left-color: transparent;
			border-color: #2779bd;
		}

		.classifybox:before {
			top: 0;
			right: 0;
		}

		.classifybox:after {
			bottom: 0;
			left: 0;
		}
	}

	.list:hover {
		box-shadow: 1 1px 20px -6px rgba(0, 0, 0, .5);
		transition: transform .5s;
		transform: scale(1.02);
		color: #34495e;
	}

	.list:nth-child(3n-1),
	.list:nth-child(3n) {
		width: 47.5% ;
		flex-direction: column ;
		height :auto ;
		.classify {
			background: linear-gradient(30deg, #ee7752, #e73c7e);
		}

		.classifybox:after,
		.classifybox:before {
			border-color: #621b18;
		}

		.list-img {
			flex: 1 0 220px;
			 height: 220px;
			 img {
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				border-bottom-left-radius: 0;
			}
		}

		.list-main {
			flex: 0 1 220px;
		}
	}
	
	
	
@media screen and (min-width: 1250px)  and (max-width:1600px) {
	
		.common{
			width:35%
		}
		
}
	
	@media screen and (max-width: 1250px) {
	
	.box{
		
			flex-flow: column;
		.common {
			 margin-left: 0px;
			  width :100%;
		
		}
	}
	
		
		.article{
			width: 100%;
		}
		.list,
	
	
		
		.list:nth-child(1) {
			
			.list-main {
			
				flex:0.5;
			
			}
		}
		
	}

	@media screen and (max-width: 750px) {
		.box{
			
				flex-flow: column;
			.common {
				 margin-left: 0px;
				  width :100%;
			
			}
		}
		
		#index .content .main {
			flex-direction: column;
		}

	.main {
		flex-direction: column;
		max-width: 100%;
	}
		
		.article{
			width: 100%;
		}

		.list .list-main h4 {
			font-size :18px;
			 font-family: cursive;
		}

		.list,
		.list:nth-child(3n-1),
		.list:nth-child(3n) {
			width :100%;
			 flex-direction: column;
			  height :auto; 
			  .list-img {
				flex: 1 0 220px;
				 height: 220px;
				  img {
					border-top-left-radius: 10px;
					border-top-right-radius: 10px;
					border-bottom-left-radius: 0;
				}
			}

			.list-main {
				flex: 0 1 220px;
			}
		}
	}
	
	
	
	
	
</style>
